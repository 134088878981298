.introduction-text {
  p {
    font-size: 20px;
    padding: 40px 30px;
    line-height: 25px;
    font-family: Plantin Std;
    font-weight: 400;
    font-style: italic;
    max-height: 400px;
    margin: 0px auto; }
  span {
    position: relative;
    display: inline-block;
    margin-top: 50px;
    font-weight: 400;
    font-style: italic; }
  span:before {
    content: url(../images/aile_droite_50.png);
    position: absolute;
    bottom: 30px;
    left: 50px; }
  a {
    color: $marron; }
  sup {
    vertical-align: top;
    margin-top: -6px;
    font-size: 70%;
    margin-left: 2px;
    display: inline-block; } }

.content-bg {
  background-image: url(../images/bg-millanges.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 130%;
  .text-right {
    text-align: right;
    @include media-mobile {
      text-align: center; } } }


.visuelorigines {
  width: 220px; }

.txt-origines {
  font-size: 18px;
  line-height: 25px;
  font-family: Plantin Std;
  font-weight: 400;
  margin: 35px 0; }

.block-vam {
  margin: 50px 0;
  text-align: center;
  @include clear-after;

  .vam-img {
    float: left;
    padding: 5px 10px;
    width: 52%;
    line-height: 300px;
    text-align: right;

    .bordered-img {
      line-height: 1;
      vertical-align: middle;
      display: inline-block; }
    img {
      max-width: 450px;
      height: auto; }
    @include media-mobile {
      width: auto;
      display: block;
      img {
        max-width: 100%; } } }

  .vam-txt {
    line-height: 345px;
    display: inline-block;
    vertical-align: middle;
    padding: 5px 10px;
    font-size: 18px;
    width: 43%;
    text-align: right;
    p {
      line-height: 25px;
      vertical-align: middle;
      display: inline-block; } }

  .fl-right {
    float: right;
    text-align: left; }

  @include media-mobile {

    .vam-txt, .vam-img, .fl-right {
      float: none;
      width: auto;
      display: block;
      text-align: center;
      line-height: 1;
      margin-top: 30px; }
    .vam-txt {
      padding: 0 15px; } } }


.author-block {
  display: table;
  margin: 30px auto;
  &__img {
    margin-left: 231px;
    margin-right: 30px;
    display: inline-block;
    vertical-align: middle;
    width: 30%;
    @include media-mobile {
      width: 100%;
      margin: auto;
      text-align: center; } }
  &__txt {
    max-width: 200px;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    font-size: 18px;
    line-height: 25px;
    @include media-mobile {
      width: 100%;
      text-align: center;
      margin-top: 20px; } } }


#popin-video {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  height: 100vh;
  width: 100%;
  background: rgba(255,255,255,0.8);
  display: none;
  iframe {
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    right: 5%;
    position: absolute; }
  #close-popin-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 35px;
    padding-top: 10px;
    background: white;
    z-index: 50;
    cursor: pointer;
    svg path {
      fill: $marron; } } }

.intro-logo {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 10%;
  right: 0;
  transform: translate(0, -30%);
  opacity: 0;
  transition: all 1.6s ease-in-out;
  width: 80%;
  text-align: center;
  &.active {
    opacity: 1;
    transform: translate(0, -50%); }
  img {
    max-width: 50%; } }

.homepage {
  .baseline {
    opacity: 0;
    transition: opacity 1s 1s;
    &.active {
      opacity: 1; } } }



