
.main-header {
  border-bottom: 5px solid $clair;
  background: white;
  .nav-bar {
    text-align: center;
    width: 1090px;
    ul {
      height: 120px;
      line-height: 120px; }
    li {
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      padding: 15px 10px;
      margin-top: 40px;
      .current-link {
        text-decoration: underline; }
      img {
        width: 250px;
        height: auto; } }
    a {
      text-decoration: none;
      color: $marron;
      &:hover {
        text-decoration: underline; } }
    li:first-child {
      margin-top: 0px; }
    li:last-child {
      margin-top: 35px; }
    .picto-english {
        width: 32px; } }

  .mobile-header {
    height: 55px;
    line-height: 55px;
    background: white;
    text-align: center;
    display: none;
    position: relative;
    z-index: 1000;
    border-bottom: 5px solid $clair;
    .logo {
      display: inline-block;
      vertical-align: middle;
      margin-top: 5px;
      line-height: 1;
      img {
        width: 110px;
        height: auto; } }
    .header-button {
      position: absolute;
      top: 0px;
      right: 10px;
      width: 40px;
      height: 40px;
      line-height: 60px;
      svg {
        display: inline-block;
        vertical-align: middle;
        max-width: 22px;
        height: auto;
        path {
          fill: $marron; } }
      .close-icon {
        display: none;
        svg {
          max-width: 20px; } }

      .open-icon {
        display: block; }
      &.opened {
        .close-icon {
          display: block; }
        .open-icon {
          display: none; } } } }

  @media screen and (max-width: 1100px) {
    border-bottom: 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    .mobile-header {
      display: block; }

    .nav-bar {
      overflow: hidden;
      height: 0;
      transition: height 0.6s;
      line-height: 100vh;
      position: relative;
      box-sizing: border-box;
      padding-top: 60px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background: white;
      &.opened {
        height: 100vh; }
      ul {
        height: auto;
        line-height: 1;
        padding-top: 20px;
        display: inline-block;
        li {
          display: block;
          margin-top: 0px;
          padding: 10px 0;
          &:last-child {
            margin-top: 10px; } } }
      .header-logo {
        display: none; } } } }

#fixed-button {
  height: 200px;
  width: 40px;
  background-color: $marron;
  position: fixed;
  bottom: 5px;
  right: 0px;
  border-top-left-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
  opacity: 0.7;
  z-index: 99999;
  @media screen and (max-width: 768px) {
    height: 160px;
    width: 35px; }
  span {
    color: #fff;
    z-index: 9999;
    display: inline-block;
    font-size: 20px;
    line-height: 60px;
    vertical-align: middle;
    letter-spacing: 2px;
    transform: rotate(-90deg);
    position: absolute;
    right: -50px;
    bottom: 70px;
    width: 130px;
    @media screen and (max-width: 768px) {
      width: 150px;
      right: -62px;
      bottom: 50px;
      font-size: 18px; } } }

@media screen and (max-width: 1100px) {
  main {
    padding-top: 60px; } }
