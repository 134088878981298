.page-mariage {

  .double-column {
    width: 1400px;
    max-width: 100%;
    margin: auto;
    margin-bottom: 10px;
    @include clear-after;

    .column-left {
      width: 50%;
      max-height: 715px;
      float: left;
      @include clear-after;
      @media screen and (max-width: 768px) {
        width: 100%;
        float: none;
        margin-top: 10px; } }

    .column-right {
      height: 500px;
      line-height: 500px;
      width: 50%;
      float: left;
      text-align: center;
      @media screen and (max-width: 768px) {
        width: 100%;
        float: none;
        height: auto; } } }

  .vam {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
    width: 100%;
    text-align: center;

    @media screen and (max-width: 768px) {
      width: 80%;
      margin-top: 20px; }

    h2 {
      font-size: 20px;
      font-weight: bold;
      line-height: 30px; }

    h3 {
      font-weight: 400;
      font-style: italic;
      font-size: 18px;
      line-height: 35px;
      margin-top: 10px;
      opacity: 0.7; }
    p {
      line-height: 20px;
      sup {
        vertical-align: top;
        margin-top: -6px;
        font-size: 70%;
        margin-left: 2px;
        display: inline-block; } } }



  .memories {
    margin-top: 30px;
    @media screen and (max-width: 768px) {
      margin-top: 10px; }

    .baseline {
      text-align: center;
      width: 100%;
      background: rgba($marron, 0.5);
      h4 {
        color: #ffffff;
        padding: 20px 18px 15px 18px;
        font-size: 25px;
        font-style: italic;
        margin: 20px 20px;
        letter-spacing: 4px;
        font-weight: 300; } }

    .avis {
      margin-top: 50px;
      @media screen and (max-width: 768px) {
        margin-top: 20px;
        width: 90%;
        text-align: center;
        display: inline-block; }
      p {
        font-family: 'Parisienne', cursive;
        font-size: 25px;
        line-height: 34px;
        position: relative; }


      p:before {
        content: '“';
        position: absolute;
        top: 110px;
        left: 300px;
        font-size: 270px;
        opacity: 0.2;
        @media screen and (max-width: 768px) {
          top: 60px;
          left: -40px;
          font-size: 200px; } }

      p:after {
        content: '„';
        position: absolute;
        bottom: 110px;
        right: 300px;
        font-size: 270px;
        opacity: 0.2;
        @media screen and (max-width: 768px) {
          bottom: 45px;
          right: -16px;
          font-size: 200px; } } }

    .auteur {
      text-align: right;
      width: 80%;
      margin-bottom: 50px;
      span {
        font-size: 16px;
        font-style: italic;
        margin-top: 20px;
        display: inline-block;
        @media screen and (max-width: 768px) {
          text-align: center;
          display: inline-block; } } } } }
