@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-Italic.eot');
  src: url('../fonts/PlantinStd-Italic.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd-Italic.woff2') format('woff2'),url('../fonts/PlantinStd-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-BoldItalic.eot');
  src: url('../fonts/PlantinStd-BoldItalic.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd-BoldItalic.woff2') format('woff2'),url('../fonts/PlantinStd-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic; }


@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-Bold.eot');
  src: url('../fonts/PlantinStd-Bold.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd-Bold.woff2') format('woff2'),url('../fonts/PlantinStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal; }


@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-Semibold.eot');
  src: url('../fonts/PlantinStd-Semibold.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd-Semibold.woff2') format('woff2'),url('../fonts/PlantinStd-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal; }


@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-Light.eot');
  src: url('../fonts/PlantinStd-Light.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd-Light.woff2') format('woff2'),url('../fonts/PlantinStd-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal; }


@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-SemiboldItalic.eot');
  src: url('../fonts/PlantinStd-SemiboldItalic.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd-SemiboldItalic.woff2') format('woff2'),url('../fonts/PlantinStd-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic; }




@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd-LightItalic.eot');
  src: url('../fonts/PlantinStd-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/PlantinStd-LightItalic.woff2') format('woff2'), url('../fonts/PlantinStd-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic; }


@font-face {
  font-family: 'Plantin Std';
  src: url('../fonts/PlantinStd.eot');
  src: url('../fonts/PlantinStd.eot?#iefix') format('embedded-opentype'),url('../fonts/PlantinStd.woff2') format('woff2'),url('../fonts/PlantinStd.woff') format('woff');
  font-weight: normal;
  font-style: normal; }


@font-face {
  font-family: 'JaneAusten';
  src: url('../fonts/JaneAusten.woff2') format('woff2'),url('../fonts/JaneAusten.woff') format('woff');
  font-weight: normal;
  font-style: normal; }

