.container {
  @include clear-after; }

.history-box {
  width: 100%;
  display: inline-block;
  float: left;
  margin-top: 30px;
  @include clear-after;
  @media screen and (max-width: 768px) {
    width: 100%;
    float: none;
    display: block; }

  h2 {
    text-align: left;
    padding-left: 35px;
    font-style: italic;
    font-size: 30px;
    margin: 25px 0 0 0;
    @include clear-after;
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-top: 10px;
      display: inline-block; } }


  p {
    font-size: 18px;
    line-height: 23px;
    padding: 35px;
    text-align: justify;
    column-count: 2;
    column-gap: 30px;
    @include clear-after;
    @media screen and (max-width: 768px) {
      text-align: center;
      column-count: 1; } } }

.marginTop {
  @media screen and (max-width: 768px) {
    margin-top: -60px; } }
