.tarifs {
  text-align: center;
  display: inline-block;
  width: 100%;
  margin-top: 30px; }

.line-one {
  width: 50%;
  display: inline-block;
  background-color: $clair;
  padding: 15px 15px 12px;
  @media screen and (max-width: 768px) {
    display: none; }

  .sejour {
    width: 25%;
    color: white;
    float: left;
    text-align: center; }
  .salle {
    width: 18%;
    color: white;
    float: right;

    text-align: center; } }

.line-two {
  width: 50%;
  display: inline-block;
  border: 1px solid $marron;
  padding: 15px;
  margin-top: 15px;
  @media screen and (max-width: 768px) {
    display: none; }


  .td-one {
    width: 20%;
    text-align: center;
    display: inline-block;
    vertical-align: middle; }
  .td-line {
    display: inline-block;
    width: 75%;
    vertical-align: middle; }

  .border {
    border-bottom: 1px solid $marron;
    margin-bottom: 8px;
    padding-bottom: 4px;
    @include clear-after;
    .td-two {
      width: 50%;
      display: inline-block;
      float: left; }
    .td-three {
      width: 25%;
      float: left;
      text-align: center; } }
  .no-border {
    @include clear-after;
    .td-two {
      width: 50%;
      display: inline-block;
      float: left; }
    .td-three {
      width: 25%;
      float: left;
      text-align: center; } } }

.txt-tarifs {
  max-width: 830px;
  margin: 25px auto;
  @media screen and (max-width: 768px) {
    padding: 0 15px; }

  p {
    text-align: center;
    font-style: italic;
    line-height: 20px; }
  span {
    position: relative;
    display: inline-block;
    height: 50px; }


  span:after {
    content: url(../images/aile_droite_50.png);
    position: absolute;
    bottom: 0px;
    left: 0px; } }


.form-container {
  margin-bottom: 30px;
  h2 {
    font-style: italic;
    font-size: 35px;
    margin: 25px 0;
    display: inline-block; }
  h4 {
    margin-bottom: 15px;
    line-height: 25px;
    a {
      color: $marron; }
    @media screen and (max-width: 768px) {
      padding: 0 20px;
      line-height: 20px; } }


  form {
    max-width: 600px;
    margin: auto;
    padding: 10px;
    .row {
      margin: 0 -10px;
      @include clear-after;
      fieldset {
        padding: 10px;
        box-sizing: border-box;
        @media screen and (max-width: 768px) {
          float: none;
          width: 90%;
          display: inline-block; } }
      &.col-2 {
        fieldset {
          float: left;
          width: 50%;
          @media screen and (max-width: 768px) {
            float: none;
            width: 90%; } } }
      &.col-3 {
        fieldset {
          float: left;
          width: 33.333%;
          @media screen and (max-width: 768px) {
            float: none;
            width: 90%; } } } }

    input {
      display: table-cell;
      width: 100%;
      color: $grey;
      height: 20px;
      padding: 2px 5px;
      @media screen and (max-width: 768px) {
        text-align: left;
        border: 1px solid $marron;
        height: 40px;
        font-size: 15px;
        font-style: italic; } }
    textarea {
      color: $grey;
      height: 100px;
      margin-top: 10px;
      resize: none;
      width: 100%;
      padding: 2px 5px;
      @media screen and (max-width: 768px) {
        width: 100%;
        border: 1px solid $marron;
        font-size: 15px;
        font-style: italic; } }
    label {
      font-size: 14px;
      font-style: italic;
      display: block;
      text-align: left;
      @media screen and (max-width: 768px) {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px; } }
    button {
      font-size: 20px;
      position: relative;
      color: $marron;
      display: table;
      margin: 10px auto;
      @media screen and (max-width: 768px) {
        border: 1px solid $marron; } } } }

.tarifs-mobile {
  display: none;
  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
    h6 {
      font-size: 18px;
      font-weight: bold;
      display: inline-block;
      margin-bottom: 10px; }

    .type-offre {
      font-style: italic;
      font-size: 19px;
      margin: 10px 0;
      display: inline-block; } }
  .titre-2 {
    margin-top: 10px; } }

.tarifs-mobile::after {
  @media screen and (max-width: 768px) {
    content: "";
    display: inline-block;
    height: 2px;
    width: 80%;
    background-color: $marron; } }

.calendrier {
  a {
    color: $marron; }
  h4 {}
  @media screen and (max-width: 768px) {
    padding: 0 10px;
    line-height: 20px; } }

