#mapDiv {}
@media screen and (max-width: 768px) {
  width: 100%; }

.page-reservation {
  form {
    display: inline-block;
    width: 600px;
    .form-line-one {
      display: inline-block;
      .input-one {
        float: left;
        width: 300px;
        text-align: left;
        h3 {
          text-align: left;
          font-weight: 400;
          font-style: italic; } } } }

  .form-line-two {
    margin-top: 30px;
    display: inline-block; }

  .encaissement {
    line-height: 22px; }

  button {
      font-size: 20px;
      position: relative;
      color: $marron; }
  span {
    position: relative;
    display: block;
    height: 50px;
    left: 15px; }


  span:after {
    content: url(../images/aile_droite_50.png);
    position: absolute;
    bottom: 0px;
    left: 0px; }

  .ml {
    font-size: 14px;
    font-style: italic;
    display: inline-block;
    margin: 30px 0 10px 0;
    line-height: 18px; } }

