.coordonnees {
  h2 {
    font-size: 30px; }

  h5 {
    font-size: 18px;
    line-height: 25px; }

  h5:nth-child(3) {
    margin-top: 10px; }

  h5:nth-child(4) {
   @media screen and (max-width: 768px) {
      padding: 0 10px; } } }

.localisation {
  margin-top: 40px;
  img {
    width: 60px; }

  .longitude {
    display: inline-block;
    padding: 5px;
    margin-left: 12px; } }

.ml {
  font-size: 14px;
  font-style: italic;
  display: inline-block;
  margin: 30px 0 10px 0;
  line-height: 18px;
  @media screen and (max-width: 768px) {
    padding: 0 20px; } }

.iframeLoc {
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%; } }
