@import 'reset';

@import 'font';
@import 'mixins';
@import 'variables';
@import 'home';
@import 'header';
@import 'commun';
@import 'region';
@import 'mariage';
@import 'reservation';
@import 'region';
@import 'contact';
@import 'tarifs';

@import 'footer';



