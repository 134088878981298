footer {
  height: 255px;
  background-color: $clair;

  .content-footer {
    padding: 20px 5px;

    img {
      width: 280px; } }

  .social-bar {
    img {
      padding: 15px 0px;
      width: 30px; } }

  span {
    color: #ffffff; } }
