body {
  text-align: center;
  color: $marron;
  font-family: Plantin Std; }
.container {
  width: 1080px;
  margin: auto;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%; } }

.content-slider {
  position: relative;
  width: 100%;
  .slider {
    position: relative;
    width: 100%; }
  .metaslider {
    clear: both;
    margin: auto; }
  .baseline {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    background: rgba($marron, 0.5);
    margin-bottom: 25px;
    @media screen and (max-width: 768px) {
      position: static; }
    p {
      color: #ffffff;
      padding: 10px;
      font-size: 18px;
      font-style: italic;
      margin-top: 9px;
      letter-spacing: 5px;
      margin-bottom: 25px;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        letter-spacing: 1px;
        padding: 10px;
        line-height: 18px; }
      span {
        font-size: 24px;
        margin-bottom: 5px; } } } }

.flex-nav-prev, .flex-nav-next {
  .flex-prev, .flex-next {
    background-image: url(../images/aile-slider.png) !important;
    width: 45px !important;
    height: 45px !important;
    opacity: 1 !important;
    background-position: center;
    @include media-mobile {
      width: 40px !important;
      height: 40px !important; } }
  .flex-prev {
    left: 10px !important; }
  .flex-next {
    right: 10px !important;
    transform: scaleX(-1) !important; } }

.bordered-img {
  display: inline-block;
  border: 3px solid $marron2;
  background: white;
  padding: 5px;
  img {
    display: block;
    max-width: 100%;
    height: auto; } }

.wing {
  position: relative;
  display: inline-block;
  height: 50px;
  width: 60px;
  &:after {
    content: url(../images/aile_droite_50.png);
    position: absolute;
    bottom: 0px;
    left: 0px; } }

#loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 999;
  img {
    position: absolute;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    max-width: 90%;
    height: auto; } }


.inline-link {
  color: $marron;
  text-decoration: underline;
  cursor: pointer; }
